import React from 'react'
import { graphql } from 'gatsby'
import styled from '@emotion/styled'
import { useIntl } from 'react-intl'

import Block from '../components/blocks'
import ParagraphBlock from '../components/blocks/ParagraphBlock'
import { ButtonLink } from '../components/Buttons'

const Introduction = styled(ParagraphBlock)`
  font-size: var(--font-size-medium);
  margin-bottom: var(--space-larger);
`

const TeachingPage = ({
  data: {
    dato: { page, pageContact }
  }
}) => {
  const { formatMessage: t } = useIntl()
  const { title, introduction, content } = page

  return (
    <>
      <h1 className="c-large">{title}</h1>
      <Introduction text={introduction} />
      <div className="separator c-small" style={{ borderWidth: 3 }} />
      {content.map(({ _modelApiKey, __typename, id, ...rest }) => (
        <Block type={_modelApiKey} key={id} {...rest} />
      ))}
      <div
        className="c-small"
        style={{
          marginTop: 'calc(-1 * var(--space-largest))',
          marginBottom: 'var(--space-largest)'
        }}
      >
        <ButtonLink to={pageContact.slug}>{t({ id: 'contact' })}</ButtonLink>
      </div>
    </>
  )
}

export default TeachingPage

export const query = graphql`
  query($locale: DATO_SiteLocale!) {
    dato {
      ...CommonData
      pageContact(locale: $locale) {
        slug: localeSlug
      }
      page: pageTeaching(locale: $locale) {
        seo: _seoMetaTags {
          ...TagRecordFragment
        }
        slug
        title
        introduction
        content {
          ... on DATO_TitleRecord {
            ...TitleRecordFragment
          }
          ... on DATO_ParagraphRecord {
            ...ParagraphRecordFragment
          }
          ... on DATO_QuoteRecord {
            ...QuoteRecordFragment
          }
          ... on DATO_ImageGridRecord {
            ...ImageGridRecordFragment
          }
          ... on DATO_GalleryRecord {
            ...GalleryRecordFragment
          }
          ... on DATO_ContentVideoRecord {
            ...VideoRecordFragment
          }
        }
      }
    }
  }
`
