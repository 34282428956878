import React from 'react'
import Video from '../Video'

const VideoBlock = ({ video }) => {
  return (
    <div className="block c-large">
      <Video {...video} />
    </div>
  )
}

export default VideoBlock
