import React from 'react'
import ParagraphBlock from './ParagraphBlock'
import ImageGridBlock from './ImageGridBlock'
import QuoteBlock from './QuoteBlock'
import VideoBlock from './VideoBlock'
import TitleBlock from './TitleBlock'
import GalleryBlock from './GalleryBlock'

const Blocks = {
  paragraph: ParagraphBlock,
  image_grid: ImageGridBlock,
  quote: QuoteBlock,
  content_video: VideoBlock,
  title: TitleBlock,
  gallery: GalleryBlock
}

const Block = ({ type, ...props }) => {
  const BlockType = Blocks[type]
  return BlockType ? <BlockType {...props} /> : null
}

export default Block
