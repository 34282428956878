import React from 'react'

const ParagraphBlock = ({ text, className, ...props }) => {
  return (
    <div
      className={`block c-small ${className}`}
      dangerouslySetInnerHTML={{ __html: text }}
      {...props}
    ></div>
  )
}

export default ParagraphBlock
