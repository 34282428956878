import React from 'react'
import { Image } from 'react-datocms'
import styled from '@emotion/styled'
import media from '../../styles/mq'

const Grid = styled.div`
  height: 110vh;
  display: flex;
  flex-direction: column;
  margin-left: var(--margin-mobile);
  margin-right: var(--margin-mobile);

  ${media.greaterThan('medium')} {
    flex-direction: row;
    margin-left: calc(var(--space-smaller) * -1);
    margin-right: calc(var(--space-smaller) * -1);
    align-items: stretch;
    padding: var(--space-large) 0;
  }
`
const TwoColumnImage = styled(Image)`
  position: relative;
  display: block;
  flex: 1;
  ${media.lessThan('medium')} {
    margin-top: var(--margin-mobile);
    margin-bottom: var(--margin-mobile);
    max-width: none !important;
    :first-of-type {
      left: calc(var(--margin-mobile) * -1);
    }
    :last-child {
      left: var(--margin-mobile);
    }
  }
  ${media.greaterThan('medium')} {
    margin: 0 var(--space-smaller);
    :first-of-type {
      top: calc(var(--space-larger) * -1);
    }
    :last-child {
      top: var(--space-larger);
    }
  }
`

const ImageGridBlock = ({ images }) => {
  if (images.length === 1)
    return (
      <Image
        className="block c-xlarge"
        data={images[0].fluidFullWidth}
        style={{ display: 'block' }}
      />
    )
  return (
    <div className="block c-xlarge">
      <Grid>
        {images.map(({ id, fixedFullHeight }) => (
          <TwoColumnImage
            className="fixedImg"
            key={id}
            data={fixedFullHeight}
          />
        ))}
      </Grid>
    </div>
  )
}

export default ImageGridBlock
